<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Assignment
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <is-authorized :permissions="['manage:fleets', 'update:fleets']">
        <button
          @click="addAssignment()"
          class="
          flex
          items-center
          bg-green-200
          border
          hover:bg-green-300
          border-green-400
          hover:border-green-500
          hover:shadow
          active:shadow-inner
          transition
          duration-300
          text-secondary
          rounded-md
          px-2
          py-1
        "
        >
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Add Assignment</span>
        </button>
      </is-authorized>
    </div>

    <div class="flex flex-wrap">
      <!-- :line-numbers="true" -->
      <vue-good-table
        v-if="assignments.length > 0"
        class="w-full"
        styleClass="vgt-table striped"
        mode="remote"
        :rows="assignments"
        :columns="columns"
        :sort-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'is_active'">
            <span
              class="px-2 rounded-lg"
              :class="{
                'bg-yellow-200 text-yellow-700': props.row.is_active == false,
                'bg-green-200 text-green-700': props.row.is_active == true,
              }"
              >{{ props.row.is_active ? "Active" : "Expired" }}</span
            >
          </span>
          <span v-else-if="props.column.field == 'actions'">
            <is-authorized :permissions="['manage:fleets', 'delete:fleets']">
              <button
                type="button"
                @click="deleteAssignment(props.row)"
                class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
            <is-authorized :permissions="['manage:fleets', 'update:fleets', 'read:fleets']">
              <button
                type="button"
                @click="handleEditAssignment(props)"
                class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
              >
                <svg
                  class="stroke-current h-5 w-5 mr-2"
                  fill="none"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  viewBox="0 0 24 24"
                >
                  <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
                </svg>
                <span></span>
              </button>
            </is-authorized>
          </span>
          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
        </template>
      </vue-good-table>
      <p class="mx-auto my-16" v-if="assignments.length == 0">
        There are no Assignments for this Fleet.
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditAssignmentPanelOpen"
        @close="addEditAssignmentPanelOpen = false"
        :title="editingAssignment.assignment_id ? 'Edit Assignment' : 'Add Assignment'"
      >
        <AddEditAssignment :assignment="editingAssignment" @complete="saveAssignment" @delete="deleteAssignment" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message" @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
const Notification = () => import("@/components/Notification.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Panel = () => import("@/components/Panel.vue");
const AddEditAssignment = () => import("@/components/Fleets/Edit/AddEditAssignment.vue");
import dateRangeOverlap from "@/utils/dateRangeOverlap";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  name: "Assignment",
  components: {
    Notification,
    ConfirmAlert,
    Panel,
    AddEditAssignment,
    IsAuthorized,
  },
  props: {
    assignments: Array,
  },
  data() {
    return {
      addEditAssignmentPanelOpen: false,
      editingAssignment: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      columns: [
        {
          label: "Assigned To",
          field: "staff_name",
          width: "20%",
        },
        {
          label: "StartDate",
          field: "requisition_startdate",
          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
          width: "20%",
        },
        {
          label: "EndDate",
          field: "requisition_enddate",
          // type: "date",
          formatFn: (v) => {
            return v == 0 ? "" : this.$moment(v).format("DD/MM/YYYY");
          },
          width: "20%",
        },
        {
          label: "Purpose",
          field: "purpose",
          width: "20%",
        },
        {
          label: "Status",
          field: "is_active",
          width: "10%",
          tdClass: this.tblClassCenterAlign,
          thClass: this.tblClassCenterAlign,
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  computed: {
    fleet_id: function() {
      return this.$store.state.lastSelectedFleet;
    },
  },
  methods: {
    tblClassLeftAlign(row) {
      return "vgt-left-align";
    },
    tblClassCenterAlign(row) {
      return "vgt-center-align";
    },
    addAssignment: function() {
      this.editingAssignment = {};
      this.addEditAssignmentPanelOpen = true;
    },
    handleEditAssignment: function(selectedRow) {
      let assignmentData = selectedRow.row;
      this.editAssignment(assignmentData);
      /*this.$router.push({
              name: "AdEditAssignment",
              params: { assignment: assignmentData },
            });*/
    },
    editAssignment: function(assignment) {
      this.editingAssignment = { ...assignment };
      this.addEditAssignmentPanelOpen = true;
    },
    saveAssignment: async function(assignment) {
      // Ensure assignment has mandatory fields filled out
      let msg;
      if (!assignment.staff_name) {
        msg = "Please enter assigned to.";
      } else if (!assignment.requisition_startdate) {
        msg = "Please provide requisition start date.";
      } else if (!assignment.requisition_enddate) {
        msg = "Please provide requisition end date.";
      } else if (
        this.$validator.isBefore(
          assignment.requisition_enddate instanceof Date ? assignment.requisition_enddate.toDateString() : assignment.requisition_enddate,
          assignment.requisition_startdate instanceof Date ? assignment.requisition_startdate.toDateString() : assignment.requisition_startdate
        )
      ) {
        msg = "Requisition start date must be less than requisition end date. New";
      }

      this.assignments.forEach(function(item) {
        if (item.assignment_id != assignment.assignment_id) {
          if (
            dateRangeOverlap(
              new Date(item.requisition_startdate),
              new Date(item.requisition_enddate),
              assignment.requisition_startdate,
              assignment.requisition_enddate
            )
          ) {
            msg = "Date overlap with other other assignments.";
          }
        }
      });

      if (msg) {
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.addEditAssignmentPanelOpen = false;

      try {
        assignment.is_active = true;
        if (assignment.requisition_enddate) {
          assignment.is_active = new Date(assignment.requisition_enddate) >= new Date();
        }
        if (!assignment.assignment_id) {
          let createResult = await this.FleetService.createFleetAssignment(this.fleet_id, assignment);
          assignment.assignment_id = createResult.data.assignment_id;
          this.assignments.push(assignment);
        } else {
          await this.FleetService.updateFleetAssignment(this.fleet_id, assignment.assignment_id, assignment);
          let idx = this.$_.findIndex(this.assignments, (c) => c.assignment_id == assignment.assignment_id);
          this.assignments.splice(idx, 1, assignment);
        }

        this.$emit("complete");

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Assignment details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !assignment.assignment_id ? "There was a problem creating the new Assignment" : "There was a problem updating the Assignment";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the Assignment"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    handlePromoteAssignmentToCurrent: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.confirmAlert.data.is_default = true;
          await this.saveAssignment(this.confirmAlert.data);
          this.assignments.forEach((c) => {
            if (c.assignment_id !== this.confirmAlert.data.assignment_id) {
              c.is_default = false;
            }
          });
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem updating the Emergency"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    promoteAssignmentToCurrent: function(assignment) {
      this.confirmAlert = {
        resultFunction: this.handlePromoteAssignmentToCurrent,
        message: `Change '${assignment.staff_name}' as primary emergency?`,
        showing: true,
        data: assignment,
      };
    },
    handleDeleteAssignment: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.FleetService.deleteAssignment(this.fleet_id, this.confirmAlert.data.assignment_id);
          let idx = this.$_.findIndex(this.assignments, (c) => {
            return c.assignment_id == this.confirmAlert.data.assignment_id;
          });

          this.$delete(this.assignments, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Assignment Deleted",
                    close: onClose,
                  },
                },
                "Successfully deleted assignment"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);

          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the Assignment"
              );
            },
            {
              position: "top-right",
            }
          );
        }

        this.isBusy = false;
        this.addEditAssignmentPanelOpen = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteAssignment: function(assignment) {
      // If we're deleting a assignment that hasn't been saved yet
      if (String(assignment.assignment_id).startsWith("pending")) {
        let idx = this.$_.findIndex(this.assignments, (c) => {
          return c.assignment_id == assignment.assignment_id;
        });

        this.$delete(this.assignments, idx);
        return;
      }

      this.confirmAlert = {
        resultFunction: this.handleDeleteAssignment,
        message: `Are you sure you wish to delete '${assignment.staff_name}' This cannot be undone.`,
        showing: true,
        data: assignment,
      };
    },
  },
};
</script>
